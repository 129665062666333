define("portal/locales/ja/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    faqTitles: {
      main: "Main",
      aigent: "アイジェント・レコメンダー"
    },
    languages: {
      en: "English",
      ja: "日本語",
      nl: "Nederlands"
    },
    components: {
      merchantSelect: {
        placeholder: "Select merchant"
      }
    },
    generic: {
      "back-to-home": "ホームヘ戻る",
      cancel: "キャンセル",
      confirmation: "確認",
      emailaddress: "メールアドレス",
      faq: "FAQ",
      "forgot-password": "パスワードをお忘れの方",
      gadget_portal: "ガジェットポータル",
      help: "ヘルプ",
      no: "いいえ",
      notification: "お知らせ",
      notifications: "お知らせ",
      ok: "OK",
      reset: "再設定",
      "reset-password": "パスワード再設定",
      save: "修正",
      welcome: "ようこそ",
      yes: "はい"
    },
    messages: {
      gadgetLimit: "１つのタブで一度に表示できるガジェットは20までです。 不要なガジェットを閉じるか、新しいタブを開いてください。",
      "no-notifications": "お知らせはありません",
      "password-expired": "パスワードの有効期限が切れました。新しいパスワードの設定をお願いします。",
      "password-reset-email-sent": "パスワード再設定用のメールを送信しました",
      "password-updated-successfully": "パスワード再設定が終了しました",
      "session-timeout-logout": "セッションがタイムアウトしたため、接続を中断します。再ログインをお願いします",
      "timeout-logout": "しばらくアクセスがなかったため、接続を中断します。再ログインをお願いします"
    },
    password: {
      strength0: "とても弱い",
      strength1: "とても弱い",
      strength2: "弱",
      strength3: "中程度",
      strength4: "強",
      strength: "強度"
    },
    intro: {
      "double-click-edit": "ダブルクリックでタブのタイトルが変更できます",
      "start-adding": "ガジェットの追加",
      "every-workspace": "ここからワークスペースにガジェットを追加できます"
    },
    tab: {
      add: "タブ追加",
      remove: "タブ削除",
      enter_new_name: "新しいタブ名を入力してください",
      update: "タブ変更",
      delete: "このタブを削除してもいいですか?",
      newTab: "新規タブ",
      highlightsTab: "Highlights"
    },
    user: {
      "my-account": "アカウント",
      login: "ログイン",
      logout: "ログアウト",
      username: "ユーザID",
      password: "パスワード",
      "password-verify": "パスワード（確認用）",
      remember_me: "次回から自動的にログイン",
      change_merchant: "お客様変更",
      switchMerchant: "マーチャントの変更"
    },
    gadgets: {
      title: "ガジェット",
      add: "ガジェット追加",
      delete: "このガジェットを閉じてもいいですか?",
      deleteState: "このガジェットを閉じてもいいですか?",
      edit: "ガジェット修正",
      editing: "修正中",
      choose_file: "ファイル修正",
      enter_filename: "ファイル名入力",
      empty: "選択されたガゼットがありません。",
      download_button: "ZIPダウンロード",
      upload_button: "ZIPアップロード",
      create_file_button: "ファイル生成",
      loading: "ロード中です",
      documentation: "文書を見る",
      save_state: "ガジェット状態保存",
      change_order: "順番変更",
      no_merchant: "マーチャントを選択してください。",
      "close-window": "メニューを閉じる"
    },
    error: {
      critical: "予期しないエラーが発生しました。少し時間を空けてから再度アクセスしてください。",
      forbidden: "操作は受け付けられません。シルバーエッグの担当にお問合せください",
      ipWhitelistFail: "許可されていないIPアドレスのため、アクセスは拒否されました",
      loginError: "ログイン情報が不正です",
      userDeactivated: "アカウントが無効になりました。アカウントを有効にするには、ご連絡ください。",
      passwordValidationError: "パスワードは10文字以上で、少なくとも1つの小文字、1つの大文字、1つの数字、および1つの記号を含める必要があります。",
      maintenance: "メンテナンス中のためログインできません。お知らせを確認してください。",
      "same-password": "他のパスワードを設定してください。",
      "password-empty-error": "パスワードを記入してください",
      "password-not-equal-error": "パスワードが一致しません",
      "password-update-error": "パスワード更新時にエラーが発生しました。お手数ですがもう一度再設定手続きをお願いします"
    }
  };
});