define("portal/services/portal-context", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    v: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.initContext();
    },
    initContext: function initContext() {
      var _this = this;
      Ember.get(this, "store").findRecord("context", "static_content").then(function (context) {
        Ember.set(_this, "v", context);
      });
    }
  });
});