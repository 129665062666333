define("portal/templates/maintenance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "RiwsclMP",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"maintenance-container\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"maintenance\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"model\",\"isStaging\"]]],null,{\"statements\":[[0,\"            \"],[7,\"div\"],[11,\"style\",\"color:red;font-size:30px;text-align:center;\"],[9],[0,\"\\n                STAGING ENVIRONMENT\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"logo\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"model\",\"logo\"]]],null,{\"statements\":[[0,\"                \"],[7,\"img\"],[12,\"class\",[23,[\"model\",\"logoClass\"]]],[12,\"src\",[23,[\"model\",\"logo\"]]],[11,\"alt\",\"\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"                \"],[1,[23,[\"model\",\"title\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"message\"],[9],[0,\"\\n            \"],[1,[21,\"message\"],false],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal/templates/maintenance.hbs"
    }
  });
});