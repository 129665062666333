define("portal/locales/en/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    faqTitles: {
      main: "Main",
      aigent: "Aigent / Recommender"
    },
    languages: {
      en: "English",
      ja: "日本語",
      nl: "Nederlands"
    },
    components: {
      merchantSelect: {
        placeholder: "Select merchant"
      }
    },
    generic: {
      "back-to-home": "Back to home",
      cancel: "Cancel",
      confirmation: "Confirmation",
      emailaddress: "E-mail address",
      faq: "FAQ",
      "forgot-password": "Forgot password",
      gadget_portal: "Gadget Portal",
      help: "Help",
      no: "No",
      notification: "Notification",
      notifications: "Notifications",
      ok: "Ok",
      reset: "Reset",
      "reset-password": "Reset password",
      save: "Save",
      welcome: "Welcome",
      yes: "Yes"
    },
    messages: {
      gadgetLimit: "Only 20 gadgets can be displayed on a single tab. Please close unnecessary gadgets or open a new tab",
      "no-notifications": "No notifications",
      "password-expired": "Your password has expired, please choose a new password",
      "password-reset-email-sent": "An email has been sent with details on how to reset your password",
      "password-updated-successfully": "Your password has been successfully adjusted",
      "session-timeout-logout": "Your session has timed out, please login again",
      "timeout-logout": "Your session has expired, please login again"
    },
    password: {
      strength0: "Very weak",
      strength1: "Weak",
      strength2: "Reasonable",
      strength3: "Strong",
      strength4: "Very strong",
      strength: "Strength"
    },
    intro: {
      "double-click-edit": "Double click to edit title",
      "start-adding": "Start adding gadgets",
      "every-workspace": "In every workspace you can stack multiple gadgets"
    },
    tab: {
      add: "Add Tab",
      remove: "Remove Tab",
      enter_new_name: "Please enter a new tab name",
      update: "Update the tab label",
      delete: "Are you sure you want to delete this tab?",
      newTab: "Unnamed Tab",
      highlightsTab: "Highlights"
    },
    user: {
      "my-account": "My account",
      login: "Login",
      logout: "Logout",
      username: "Username",
      password: "Password",
      "password-verify": "Verify password",
      remember_me: "Remember me",
      change_merchant: "Change Merchant",
      switchMerchant: "Switch merchant"
    },
    gadgets: {
      add: "Add gadget",
      delete: "Are you sure you want to delete this gadget?",
      documentation: "View docs",
      empty: "No gadgets configured.",
      loading: "Please wait while the gadgets are loading.",
      no_merchant: "Please select a merchant first",
      save_state: "Save gadget state",
      title: "Gadgets",
      "close-window": "Close window"
    },
    error: {
      critical: "An unexpected error has occurred, please try again later",
      forbidden: "Operation not allowed. Please contact the support desk.",
      ipWhitelistFail: "Access denied as your IP is not white-listed for this website",
      loginError: "Incorrect login credentials.",
      userDeactivated: "Your account has been disabled. Please contact us to enable your account.",
      passwordValidationError: "A password is weak, please use a minimum length of at least 10 upper case, lower case, numbers and symbol or punctuation characters.",
      maintenance: "Unable to login during maintenance, see notification message below",
      "password-empty-error": "No password specified",
      "password-not-equal-error": "Passwords do not match",
      "password-update-error": "An error occurred when trying to update your password. Please try resetting your password again",
      "same-password": "Password not allowed, please choose a different password"
    }
  };
});