define("portal/components/input-field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "0ZJ0InmC",
    "block": "{\"symbols\":[\"error\"],\"statements\":[[4,\"if\",[[23,[\"label\"]]],null,{\"statements\":[[0,\"    \"],[7,\"label\"],[9],[1,[21,\"label\"],false],[0,\":\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[5,\"one-way-input\",[],[[\"@type\",\"@name\",\"@readonly\",\"@placeholder\",\"@checked\",\"@value\",\"@update\"],[[21,\"type\"],[21,\"name\"],[21,\"readonly\"],[21,\"placeholder\"],[21,\"checked\"],[21,\"value\"],[27,\"action\",[[22,0,[]],\"update\"],null]]]],[0,\"\\n\\n\"],[4,\"each\",[[23,[\"errors\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"login-error\"],[9],[1,[27,\"t\",[[27,\"concat\",[\"error.\",[22,1,[\"message\"]]],null]],null],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal/components/input-field/template.hbs"
    }
  });
});