define("portal/models/tab-state", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var _default = _exports.default = Model.extend({
    position: attr("number"),
    isActive: attr("boolean"),
    url: attr("string"),
    gadget: belongsTo("gadget", {
      async: false
    }),
    merchant: belongsTo("merchant"),
    tab: belongsTo("tab")
  });
});