define("portal/styles/compass/test/helper/compare", [], function () {
  "use strict";

  var render = require("portal/styles/compass/test/helper/render");
  var ruleset = require("portal/styles/compass/test/helper/ruleset");
  module.exports = function (spec, inputRuleset, expectedOutput, imports) {
    return it(spec, function (done) {
      render(ruleset(inputRuleset), function (output, err) {
        expect(output).toBe(ruleset(expectedOutput));
        done();
      }, imports);
    });
  };
});