define("portal/helpers/has", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.has = has;
  function has(params) {
    var needle = params[0];
    var haystack = params[1];
    return haystack.includes(needle);
  }
  var _default = _exports.default = Ember.Helper.helper(has);
});