define("portal/templates/password-reset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "8f7UGQoo",
    "block": "{\"symbols\":[],\"statements\":[[5,\"page-password-reset\",[],[[\"@model\"],[[21,\"model\"]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal/templates/password-reset.hbs"
    }
  });
});