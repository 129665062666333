define("portal/templates/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "l0F70McO",
    "block": "{\"symbols\":[],\"statements\":[[5,\"page-dashboard\",[],[[\"@gadgetLimitNotification\",\"@hideGadgetContainer\",\"@addTab\",\"@saveTab\",\"@removeTab\",\"@addGadgetToTab\",\"@logout\",\"@model\"],[[21,\"gadgetLimitNotification\"],[21,\"hideGadgetContainer\"],[27,\"route-action\",[\"addTab\"],null],[27,\"route-action\",[\"saveTab\"],null],[27,\"route-action\",[\"removeTab\"],null],[27,\"route-action\",[\"addGadgetToTab\"],null],[27,\"route-action\",[\"logout\"],null],[21,\"model\"]]]],[0,\"\\n\\n\"],[5,\"popup-notification\",[],[[\"@popupVisible\",\"@hideClose\",\"@text\",\"@confirm\"],[[27,\"mut\",[[23,[\"logoutNotification\"]]],null],true,[27,\"mut\",[[23,[\"logoutText\"]]],null],[27,\"route-action\",[\"redirectToLogin\"],null]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal/templates/dashboard.hbs"
    }
  });
});