define("portal/components/page-password-reset/component", ["exports", "portal/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    classNames: ["login"],
    params: Ember.computed(function () {
      /* istanbul ignore else */
      if (_environment.default.environment === "test") {
        return Ember.get(this, "mockParams");
      }
      /* istanbul ignore next */
      var search = location.search.substring(1);
      /* istanbul ignore next */
      return JSON.parse("{\"" + decodeURI(search).replace(/"/g, "\\\"").replace(/&/g, "\",\"").replace(/=/g, "\":\"") + "\"}");
    }),
    expired: Ember.computed(function () {
      var params = Ember.get(this, "params");
      if (params["expired"]) {
        return true;
      } else {
        return false;
      }
    }),
    actions: {
      resetPassword: function resetPassword() {
        Ember.set(this, "error", null);
        var self = this;
        var token = Ember.get(this, "params")["token"];
        var password = Ember.get(this, "password");
        var password2 = Ember.get(this, "password2");
        var csrfToken = Ember.$("[name='csrf']")[0].content;
        if (!password || !password2) {
          Ember.set(this, "errors", [{
            message: "password-empty-error"
          }]);
          return;
        }
        if (password !== password2) {
          Ember.set(this, "errors", [{
            message: "password-not-equal-error"
          }]);
          return;
        }
        var url = "/api3/users/password/update?csrf=".concat(csrfToken);
        Ember.get(this, "ajax").patch(url, {
          data: {
            token: token,
            password: password
          }
        }).then(function (data) {
          if (data["error"] === "invalid") {
            Ember.set(self, "errors", [{
              message: ["password-update-error"]
            }]);
          } else if (data["error"] === "same-password") {
            Ember.set(self, "errors", [{
              message: ["same-password"]
            }]);
          } else if (data["error"] === "passwordValidationError") {
            Ember.set(self, "errors", [{
              message: "passwordValidationError"
            }]);
          } else {
            Ember.set(self, "passwordUpdated", true);
          }
        }, function (response) {
          /* istanbul ignore next */
          var errors = Ember.A([]);
          /* istanbul ignore next */
          response.errors.forEach(function (error) {
            if (error["detail"]["error"]) {
              errors.pushObject({
                message: error["detail"]["error"]
              });
            }
            // Forbidden error likely caused by IP filtering
            if (error["detail"]["title"]) {
              errors.pushObject({
                message: error["detail"]["title"].toLowerCase()
              });
            }
          });
          /* istanbul ignore next */
          Ember.set(self, "errors", errors);
        });
      }
    }
  });
});