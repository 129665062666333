define("portal/utils/random-hex", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var randomHex = function randomHex() {
    return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
  };
  var _default = _exports.default = randomHex;
});