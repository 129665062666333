define("portal/components/page-login/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    classNames: ["login"],
    actions: {
      authenticate: function authenticate() {
        var _this = this;
        var username = Ember.get(this, "username");
        var password = Ember.get(this, "password");
        var session = Ember.get(this, "session");
        session.authenticate(username, password).then(function (result) {
          if (!result) {
            // An error occurred, display a message
            var errors = Ember.A(Ember.get(session, "errors"));
            errors.pushObject({
              message: "critical"
            });
            Ember.set(_this, "session.errors", errors);
          }
        });
      },
      passwordReset: function passwordReset() {
        var username = Ember.get(this, "resetEmail");
        var session = Ember.get(this, "session");
        if (!username) {
          return;
        } else {
          Ember.set(this, "successMessage", true);
        }
        session.sendResetEmail(username).then(function () {});
        Ember.set(this, "resetEmail", "");
      },
      hideSuccess: function hideSuccess() {
        Ember.set(this, "successMessage", false);
      }
    }
  });
});