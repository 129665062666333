define("portal/components/input-field/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNameBindings: ["isTextField:field", "errors:error"],
    type: "text",
    isTextField: Ember.computed("type", function () {
      return ["text", "textarea", "password"].includes(Ember.get(this, "type"));
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      /* istanbul ignore if */
      if (!Ember.get(this, "update")) {
        throw new Error("You must provide an `update` action to `{{".concat(this.templateName, "}}`."));
      }
    },
    actions: {
      update: function update(val) {
        Ember.get(this, "update")(val);
      }
    }
  });
});