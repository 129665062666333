define("portal/utils/query-param", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var getQueryParam = function getParameterByName(name) {
    var match = new RegExp("[?&]" + name + "=([^&]*)").exec(window.location.search);
    return match && /* istanbul ignore next */decodeURIComponent(match[1].replace(/\+/g, " "));
  };
  var _default = _exports.default = getQueryParam;
});