define("portal/models/translation", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var _default = _exports.default = Model.extend({
    en: attr("string"),
    ja: attr("string"),
    nl: attr("string"),
    trans: Ember.computed(function () {
      return Ember.get(this, window.LOCALE);
    })
  });
});