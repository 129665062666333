define("portal/models/tab", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var hasMany = _emberData.default.hasMany;
  var _default = _exports.default = Model.extend({
    label: attr("string"),
    position: attr("number"),
    tabStates: hasMany("tabState"),
    isActive: attr("boolean"),
    containerClassName: Ember.computed("isActive", function () {
      if (Ember.get(this, "isActive")) {
        return "active";
      } else {
        return "";
      }
    })
  });
});