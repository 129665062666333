define("portal/components/login-notifications/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ErCq0hXV",
    "block": "{\"symbols\":[\"notification\"],\"statements\":[[4,\"each\",[[23,[\"notifications\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"notification\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"btn\"],[9],[0,\"!\"],[10],[0,\"\\n        \"],[7,\"label\"],[9],[1,[22,1,[\"title\"]],false],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"clearfix\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal/components/login-notifications/template.hbs"
    }
  });
});