define("portal/components/top-menu/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    userManagerGadget: Ember.computed("model", function () {
      var categories = Ember.get(this, "model.categories");
      var administrationCategory = categories.findBy("id", "administration");
      if (!administrationCategory) {
        // Should not happen, all roles need the user-manager gadget
        return {};
      }
      return administrationCategory.get("gadgets").findBy("id", "user-manager");
    })
  });
});