define("portal/components/merchant-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "wk1ijDQP",
    "block": "{\"symbols\":[\"merchant\"],\"statements\":[[5,\"power-select\",[[12,\"renderInPlace\",[21,\"renderInPlace\"]]],[[\"@placeholder\",\"@searchPlaceholder\",\"@search\",\"@onchange\"],[[27,\"t\",[\"components.merchantSelect.placeholder\"],null],[27,\"t\",[\"components.merchantSelect.placeholder\"],null],[27,\"perform\",[[23,[\"searchMerchant\"]]],null],[27,\"action\",[[22,0,[]],\"updateMerchant\"],null]]],{\"statements\":[[0,\"\\n    \"],[1,[22,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal/components/merchant-select/template.hbs"
    }
  });
});