define("portal/models/gadget", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var _default = _exports.default = Model.extend({
    title: attr("i18n"),
    endpoint: attr("string"),
    isGlobal: attr("boolean"),
    category: belongsTo("category"),
    order: attr("number")
  });
});