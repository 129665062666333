define("portal/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "UoqnWYPE",
    "block": "{\"symbols\":[],\"statements\":[[0,\" \"],[1,[21,\"outlet\"],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"id\",\"destination\"],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal/templates/application.hbs"
    }
  });
});