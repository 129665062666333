define("portal/components/gadget-container/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    session: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, "setRoles", ["admin", "cr-tech", "accounting", "approval", "customer-relations", "crmember", "set-user", "set-commission-view-only"]);
    },
    userRoles: Ember.computed("session.user.roles", function () {
      return Ember.get(this, "session.user.roles").mapBy("id");
    }),
    userHasSetRole: Ember.computed.intersect("userRoles", "setRoles"),
    hasMerchantSelect: Ember.computed("session", function () {
      var userSetRoles = Ember.get(this, "userHasSetRole");
      if (Ember.get(this, "session.user.merchants.length") > 1 || userSetRoles.length > 0) {
        return true;
      } else {
        return false;
      }
    }),
    actions: {
      toggleState: function toggleState(state) {
        if (Ember.get(state, "isActive")) {
          Ember.set(state, "isActive", false);
          state.save();
        } else {
          this.send("activateState", state);
        }
      },
      removeStateConfirm: function removeStateConfirm(state) {
        Ember.set(this, "deleteConfirmation", true);
        Ember.set(this, "markedState", state);
      },
      removeState: function removeState() {
        Ember.get(this, "markedState").destroyRecord();
        Ember.set(this, "markedState", null);
      },
      activateState: function activateState(_activateState) {
        var _this = this;
        var states = Ember.get(this, "tab.tabStates");
        states.forEach(function (state, index) {
          if (state === _activateState) {
            Ember.set(state, "position", Ember.get(states, "length"));
            Ember.set(state, "isActive", true);
          } else {
            Ember.set(state, "position", index);
            Ember.set(state, "isActive", false);
          }
          state.save();
        });
        Ember.run.scheduleOnce("afterRender", this, function () {
          var currentElement = _this.$();
          /* istanbul ignore else */
          if (currentElement) {
            var activeBlock = currentElement.find(".block.active");
            /* istanbul ignore else */
            if (activeBlock) {
              var blockOffset = activeBlock.position().top;
              Ember.$("html, body").animate({
                scrollTop: blockOffset
              }, 1000);
            }
          }
        });
      }
    }
  });
});