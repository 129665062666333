define("portal/router", ["exports", "portal/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route("dashboard", {
      path: "/"
    });
    this.route("login");
    this.route("password-reset");
    this.route("maintenance");
  });
  var _default = _exports.default = Router;
});