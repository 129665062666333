define("portal/routes/application", ["exports", "portal/mixins/maintenance-mixin"], function (_exports, _maintenanceMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_maintenanceMixin.default, {
    i18n: Ember.inject.service(),
    session: Ember.inject.service(),
    model: function model() {
      var maintenance = Ember.get(this, "inMaintenance");
      if (!maintenance) {
        return Ember.RSVP.hash({
          user: Ember.get(this, "session").initUser(),
          portalContext: this.store.find("context", "static_content"),
          notifications: this.store.query("notification", {
            on_login_page: true
          })
        });
      }
    },
    afterModel: function afterModel() {
      var maintenance = Ember.get(this, "inMaintenance");
      if (!maintenance) {
        var allowedPaths = ["/password-reset"];
        var allowed = allowedPaths.indexOf(window.location.pathname) !== -1;
        if (!Ember.get(this, "session.user") && allowed === false) {
          this.transitionTo("login");
        }
      }
    }
  });
});