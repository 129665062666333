define("portal/components/faq-pages/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: "",
    i18n: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      var page = Ember.get(this, "faqPages").findBy("id", "main");
      Ember.set(page, "isActive", true);
    },
    activeFaq: Ember.computed("faqPages", function () {
      var language = Ember.get(this, "i18n.locale");
      var page = Ember.get(this, "faqPages").findBy("id", "main");
      return page.get(language);
    }),
    actions: {
      switchPage: function switchPage(id) {
        var currentPage = Ember.get(this, "faqPages").findBy("isActive", true);
        Ember.set(currentPage, "isActive", false);
        var language = Ember.get(this, "i18n.locale");
        var newPage = Ember.get(this, "faqPages").findBy("id", id);
        Ember.set(this, "activeFaq", newPage.get(language));
        Ember.set(newPage, "isActive", true);
      }
    }
  });
});