define("portal/services/session", ["exports", "moment", "portal/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    moment: Ember.inject.service(),
    i18n: Ember.inject.service(),
    ajax: Ember.inject.service(),
    user: null,
    redirect: function redirect(path) {
      // Cannot overwrite redirect in tests
      /* istanbul ignore else */
      if (_environment.default.environment === "test") {
        return path;
      } else {
        window.location.href = path;
      }
    },
    authenticate: function authenticate(username, password) {
      var _this = this;
      return Ember.get(this, "ajax").post("/api3/users/login", {
        data: {
          username: username,
          password: password
        }
      }).then(function (result) {
        Ember.set(_this, "errors", []);
        if (result.error === "invalid_credentials") {
          Ember.set(_this, "errors", [{
            message: "loginError"
          }]);
        } else if (result.error === "user_deactivated") {
          Ember.set(_this, "errors", [{
            message: "userDeactivated"
          }]);
        } else if (result.error === "maintenance") {
          Ember.set(_this, "errors", [{
            message: "maintenance"
          }]);
        } else if (result.error === "password-expired") {
          _this.redirect(result.location);
        } else if (result.error === "ip_whitelist_fail") {
          Ember.set(_this, "errors", [{
            message: "ipWhitelistFail"
          }]);
        } else {
          // A screen "refresh" is needed for the browser to notice the session cookie
          _this.redirect("/");
        }
        return true;
      }).catch(function () {
        return false;
      });
    },
    initUser: function initUser() {
      var _this2 = this;
      return Ember.get(this, "store").queryRecord("user", {
        me: true
      }).then(function (user) {
        Ember.set(_this2, "user", user);
        _this2.initEnvironment();
      }).catch(function () {
        // Redirect loop
        _this2.invalidate();
      });
    },
    initEnvironment: function initEnvironment() {
      var locale = Ember.get(this, "user.language");
      if (locale) {
        Ember.set(this, "i18n.locale", locale);
        _moment.default.locale(locale);
        Cookies.set("locale", locale, {
          sameSite: 'Strict',
          secure: document.location.protocol === 'https:'
        });
      }
    },
    invalidate: function invalidate() {
      var redirect = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      var self = this;
      var ajax = Ember.get(this, "ajax");
      ajax.request("/api3/users/logout").then(function () {
        /* istanbul ignore next */
        if (redirect) {
          return self.redirect("/");
        }
      }).catch(function () {});
    },
    sendResetEmail: function sendResetEmail(username) {
      var csrfToken = Ember.$("[name='csrf']")[0].content;
      return Ember.get(this, "ajax").post("/api3/users/password/reset", {
        data: {
          username: username,
          csrf: csrfToken
        }
      });
    }
  });
});