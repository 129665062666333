define("portal/adapters/application", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.JSONAPIAdapter.extend({
    namespace: "api3",
    handleResponse: function handleResponse(status, headers, payload) {
      if (status === 400) {
        var message = {
          type: "portal",
          action: "logout"
        };
        window.postMessage(message, "*");
        var errors = this.normalizeErrorResponse(status, headers, payload);
        return new _emberData.default.InvalidError(errors);
      }
      return this._super.apply(this, arguments);
    }
  });
});