define("portal/components/gadget-menu/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ["gadgets"],
    classNameBindings: ["active"],
    mouseLeave: function mouseLeave() {
      Ember.set(this, "active", false);
    },
    active: false,
    categoryOpen: null,
    activeGadgets: Ember.computed("tab.tabStates.@each.gadget", function () {
      var gadgets = Ember.A([]);
      Ember.get(this, "tab.tabStates").forEach(function (state) {
        gadgets.pushObject(Ember.get(state, "gadget.id"));
      });
      return gadgets;
    }),
    actions: {
      clearNotification: function clearNotification() {
        Ember.set(this, "gadgetLimitNotification", false);
      },
      toggleMenu: function toggleMenu() {
        this.toggleProperty("active");
      },
      toggleCategory: function toggleCategory(index) {
        if (Ember.get(this, "categoryOpen") === index) {
          Ember.set(this, "categoryOpen", null);
        } else {
          Ember.set(this, "categoryOpen", index);
        }
      }
    }
  });
});