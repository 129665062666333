define("portal/initializers/iframe-access", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "iframe-access",
    initialize: function initialize() {
      // Makes sure iframes can be accessed by its parent
      /* istanbul ignore if */
      if (document.domain.indexOf("silveregg.net") > 0) {
        document.domain = "silveregg.net";
      } else {
        document.domain = document.domain;
      }
    }
  };
});