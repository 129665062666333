define("portal/routes/maintenance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    i18n: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var _this = this;
      this._super.apply(this, arguments);
      // Returning to force setupController to run after beforeModel, otherwise
      // the message will not exist on the route
      return Ember.get(this, "ajax").request("/in_maintenance").then(function (response) {
        if (response.maintenance === false) {
          _this.transitionTo("login");
        }
        Ember.set(_this, "en", response.en);
        Ember.set(_this, "ja", response.ja);

        // Set locale based on browser
        var i18n = Ember.get(_this, "i18n");
        var getNavigatorLanguage = /* istanbul ignore next */function getNavigatorLanguage() {
          return navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.userLanguage || navigator.language || navigator.browserLanguage || "ja";
        };
        var locale = getNavigatorLanguage().slice(0, 2);
        var acceptableLanguages = ["en", "ja"];

        /* istanbul ignore if */
        if (acceptableLanguages.indexOf(locale) === -1) {
          locale = "ja";
        }
        i18n.locale = locale;
        Ember.set(_this, "message", Ember.get(_this, locale));
      });
    },
    model: function model() {
      return this.store.find("context", "static_content");
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      Ember.set(controller, "message", Ember.get(this, "message"));
    }
  });
});