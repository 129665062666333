define("portal/components/one-way-input-focus/component", ["exports", "ember-one-way-controls/components/one-way-input"], function (_exports, _oneWayInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _oneWayInput.default.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.$().focus();
    }
  });
});