define("portal/components/popup-notification/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    renderInPlace: false,
    // Set to true during tests
    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, "destination", Ember.get(this, "destination") || Ember.$("#destination"));
    },
    didInsertElement: function didInsertElement() {
      Ember.$("body").width(Ember.$("body").width() + 1).width("auto");
      Ember.$(document).on("keypress", this._keypressHandler);
    },
    didRender: Ember.observer("popupVisible", function () {
      this.scrollAndAdjust();
    }),
    keyDown: function keyDown(event) {
      if (event.keyCode === 13) {
        this.send("confirm");
      } else if (event.keyCode === 27) {
        this.send("close");
      }
    },
    scrollAndAdjust: function scrollAndAdjust() {
      var _this = this;
      this.$().attr({
        tabindex: 1
      });
      this.$().focus();
      if (Ember.get(this, "popupVisible")) {
        Ember.run.scheduleOnce("afterRender", this, function () {
          var top = Ember.$(window).scrollTop() + 100;
          var dest = _this.get("destination");
          dest.find(".popup-wrapper").css("margin-top", "+=".concat(top, "px"));
          dest.find(".overlay").on("click", function () {
            _this.send("close");
          });
        });
      }
    },
    actions: {
      close: function close() {
        Ember.set(this, "popupVisible", false);
        this.$().parents("html").attr({
          tabindex: 1
        });
        this.$().parents("html").focus();
      },
      confirm: function confirm() {
        Ember.get(this, "confirm")();
        this.send("close");
      }
    }
  });
});