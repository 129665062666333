define("portal/components/language-select/component", ["exports", "portal/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ["topnav", "language"],
    i18n: Ember.inject.service(),
    session: Ember.inject.service(),
    reload: function reload() {
      /* istanbul ignore else */
      if (_environment.default.environment === "test") {
        return true;
      } else {
        document.location.reload();
      }
    },
    locales: Ember.computed("i18n.{locale,locales}", function () {
      var i18n = Ember.get(this, "i18n");
      return Ember.get(this, "i18n.locales").map(function (loc) {
        return {
          id: loc,
          text: i18n.t("languages." + loc)
        };
      });
    }),
    actions: {
      switchLocale: function switchLocale(locale) {
        var self = this;
        var user = Ember.get(this, "session.user");
        user.set("language", locale);
        user.save().then(function () {
          Cookies.set("locale", locale, {
            sameSite: "Strict",
            secure: document.location.protocol === 'https:'
          });
          self.reload();
        });
      }
    }
  });
});