define("portal/initializers/csrf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "csrf",
    initialize: function initialize() {
      Ember.$.ajaxPrefilter(function (options, originalOptions, xhr) {
        return xhr.setRequestHeader("X-CSRFToken", Ember.$('meta[name="csrf"]').attr("content"));
      });
    }
  };
});