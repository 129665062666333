define("portal/components/tab-menu/component", ["exports", "portal/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      var tabs = Ember.get(this, "tabs");
      var tabOrder = {};
      tabs.forEach(function (tab, index) {
        tabOrder[index] = tab;
      });
      Ember.set(this, "tabOrder", tabOrder);
    },
    actions: {
      dragEnd: function dragEnd() {
        var hasChanged = false;
        var tabOrder = Ember.get(this, "tabOrder");
        var tabs = Ember.get(this, "tabs");
        var newTabOrder = {};

        /* istanbul ignore if */
        if (_environment.default.environment === "test" && Ember.get(this, "forceOrder")) {
          // Hack to support the coverage
          tabs = tabs.reverse();
          var firstItem = Ember.$(".sortMe:first-child");
          firstItem.next().insertBefore(firstItem);
        }
        tabs.forEach(function (tab, index) {
          newTabOrder[index] = tab;
          if (newTabOrder[index] !== tabOrder[index]) {
            hasChanged = true;
          }
        });
        if (hasChanged) {
          for (var index in newTabOrder) {
            newTabOrder[index].set("position", parseInt(index));
            newTabOrder[index].save();
          }
        }
        Ember.set(this, "tabOrder", newTabOrder);
      },
      activateTab: function activateTab(tab) {
        var tabs = Ember.get(this, "tabs");

        /* istanbul ignore else */
        if (!Ember.get(tab, "isActive")) {
          tabs.forEach(function (tab) {
            /* istanbul ignore else */
            if (!tab.get("isDeleted")) {
              tab.set("isActive", false);
            }
          });
          Ember.set(tab, "isActive", true);
          tabs.forEach(function (tab) {
            return tab.save();
          });
        }
      },
      removeTabConfirm: function removeTabConfirm(tab) {
        Ember.set(this, "markedTab", tab);
        Ember.set(this, "deleteConfirmation", true);
      },
      removeTab: function removeTab() {
        var _this = this;
        var tab = Ember.get(this, "markedTab");
        if (Ember.get(tab, "isActive")) {
          Ember.get(this, "removeTab")(tab).then(function () {
            _this.send("activateTab", Ember.get(_this, "tabs.firstObject"));
          });
        } else {
          Ember.get(this, "removeTab")(tab);
        }
        Ember.set(this, "markedTab", null);
      }
    }
  });
});