define("portal/models/notification", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var _default = _exports.default = Model.extend({
    title: attr("string"),
    description: attr("string"),
    htmlSafe: Ember.computed("description", function () {
      var description = Ember.get(this, "description");
      return Ember.String.htmlSafe(description);
    })
  });
});