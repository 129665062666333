define("portal/models/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;
  var _default = _exports.default = Model.extend({
    username: attr("string"),
    name: attr("string"),
    title: attr("string"),
    language: attr("string"),
    tabs: hasMany("tab", {
      async: true
    }),
    roles: hasMany("role", {
      async: true
    }),
    merchants: hasMany("merchant", {
      async: true
    }),
    activeMerchant: belongsTo("merchant"),
    password: attr("string"),
    isDemo: attr("boolean"),
    created: attr("date"),
    activated: attr("date"),
    isNotLimited: Ember.computed("roles", function () {
      var roles = Ember.get(this, "roles");
      var limitedRole = roles.findBy("id", "limited");
      if (limitedRole) {
        return false;
      }
      return true;
    })
  });
});