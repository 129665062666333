define("portal/routes/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    i18n: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, "locales", ["en", "ja"]);
    },
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      if (this.get("session.user")) {
        this.transitionTo("/");
      }
    },
    afterModel: function afterModel() {
      var localeName = (navigator.language || /* istanbul ignore next */navigator.browserLanguage).split("-")[0];
      if (Ember.get(this, "locales").indexOf(localeName) === -1) {
        localeName = "en";
      }
      Ember.set(this, "i18n.locale", localeName);
    },
    actions: {
      didTransition: function didTransition() {
        Ember.$("html").attr("class", "page-login");
      }
    }
  });
});