define("portal/templates/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "V1BM+XbJ",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"page-login\",null,[[\"model\"],[[23,[\"model\"]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[27,\"or\",[[23,[\"model\",\"portalContext\",\"companyUrl\"]],[23,[\"model\",\"portalContext\",\"footer\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"footer\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"model\",\"portalContext\",\"companyUrl\"]]],null,{\"statements\":[[0,\"            \"],[7,\"div\"],[11,\"class\",\"left\"],[9],[0,\"\\n                \"],[7,\"a\"],[12,\"href\",[23,[\"model\",\"portalContext\",\"companyUrl\"]]],[11,\"target\",\"_blank\"],[11,\"rel\",\"noopener noreferrer\"],[9],[1,[23,[\"model\",\"portalContext\",\"companyProfile\"]],false],[10],[0,\"\\n                \"],[7,\"a\"],[12,\"href\",[23,[\"model\",\"portalContext\",\"pdfUrl\"]]],[11,\"target\",\"_blank\"],[11,\"rel\",\"noopener noreferrer\"],[9],[0,\"ヘルプ\"],[10],[0,\"\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"model\",\"portalContext\",\"footer\"]]],null,{\"statements\":[[0,\"            \"],[7,\"div\"],[11,\"class\",\"right\"],[9],[0,\"\\n                \"],[1,[23,[\"model\",\"portalContext\",\"footer\"]],false],[0,\"\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal/templates/login.hbs"
    }
  });
});