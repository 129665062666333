define("portal/mixins/maintenance-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    ajax: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var _this = this;
      this._super.apply(this, arguments);
      return Ember.get(this, "ajax").request("/in_maintenance").then(function (response) {
        if (response.maintenance === true) {
          Ember.set(_this, "inMaintenance", true);
          _this.transitionTo("maintenance");
        } else {
          Ember.set(_this, "inMaintenance", false);
        }
      });
    }
  });
});