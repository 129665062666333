define("portal/initializers/power-select", ["exports", "ember-power-select/components/power-select/before-options"], function (_exports, _beforeOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // NOTE: Fixed the autofocus issue of ember-power-select
  // https://github.com/cibernox/ember-power-select/issues/1130
  function initialize() {
    _beforeOptions.default.reopen({
      focusInput: function focusInput() {
        this.input = document.querySelector(".ember-power-select-search-input[aria-controls=\"".concat(this.listboxId, "\"]"));

        /* istanbul ignore else */
        if (this.input) {
          Ember.run.next(this.input, "focus");
        }
      }
    });
  }
  var _default = _exports.default = {
    name: "power-select",
    initialize: initialize
  };
});