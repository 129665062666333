define("portal/components/faq-pages/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "9eKUa1WG",
    "block": "{\"symbols\":[\"faq\"],\"statements\":[[7,\"h1\"],[9],[1,[27,\"t\",[\"generic.faq\"],null],false],[10],[0,\"\\n\\n\"],[7,\"p\"],[11,\"class\",\"menu\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"faqPages\"]]],null,{\"statements\":[[0,\"        \"],[7,\"span\"],[9],[0,\"\\n            \"],[7,\"a\"],[11,\"href\",\"#\"],[12,\"class\",[27,\"if\",[[22,1,[\"isActive\"]],\"active\"],null]],[9],[0,\"\\n                \"],[1,[27,\"t\",[[27,\"concat\",[\"faqTitles.\",[22,1,[\"id\"]]],null]],null],false],[0,\"\\n            \"],[10],[0,\"\\n        \"],[3,\"action\",[[22,0,[]],\"switchPage\",[22,1,[\"id\"]]]],[10],[0,\"\\n\"],[4,\"unless\",[[27,\"eq\",[[23,[\"faqPages\",\"lastObject\"]],[22,1,[]]],null]],null,{\"statements\":[[0,\"            |\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[10],[0,\"\\n\\n\"],[7,\"p\"],[11,\"class\",\"scrollable\"],[9],[0,\"\\n    \"],[1,[27,\"markdown-to-html\",[[23,[\"activeFaq\"]]],[[\"tagName\"],[\"p\"]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal/components/faq-pages/template.hbs"
    }
  });
});