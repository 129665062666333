define("portal/components/language-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "I4Kml46X",
    "block": "{\"symbols\":[\"locale\"],\"statements\":[[7,\"div\"],[11,\"class\",\"btn\"],[9],[1,[23,[\"i18n\",\"locale\"]],false],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"foldout\"],[9],[0,\"\\n    \"],[7,\"span\"],[12,\"class\",[28,[\"tip \",[21,\"context\"],[27,\"if\",[[27,\"not\",[[23,[\"isLimited\"]]],null],\"-limited\"],null]]]],[9],[10],[0,\"\\n    \"],[7,\"ul\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"locales\"]]],null,{\"statements\":[[0,\"            \"],[7,\"li\"],[9],[0,\"\\n                \"],[7,\"a\"],[12,\"class\",[27,\"if\",[[27,\"eq\",[[23,[\"i18n\",\"locale\"]],[22,1,[\"id\"]]],null],\"active\"],null]],[9],[0,\"\\n                    \"],[7,\"span\"],[11,\"class\",\"flag\"],[9],[7,\"img\"],[12,\"src\",[28,[\"assets/images/flag-\",[22,1,[\"id\"]],\".png\"]]],[11,\"alt\",\"\"],[9],[10],[10],[0,\"\\n                    \"],[1,[22,1,[\"text\"]],false],[0,\"\\n                \"],[3,\"action\",[[22,0,[]],\"switchLocale\",[22,1,[\"id\"]]]],[10],[0,\"\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal/components/language-select/template.hbs"
    }
  });
});