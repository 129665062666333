define("portal/models/merchant", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var _default = _exports.default = Model.extend({
    name: attr("string"),
    currency: attr("string"),
    currencyUsesDecimal: attr("boolean") // TODO: can't we improve this?
  });
});