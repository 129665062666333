define("portal/initializers/session", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(app) {
    app.register("service:session", Ember.Object);
    app.inject("route", "session", "service:session");
    app.inject("controller", "session", "service:session");
    app.inject("component", "session", "service:session");
  }
  var _default = _exports.default = {
    name: "session",
    initialize: initialize
  };
});